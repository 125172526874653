.contact_section_container{
display: flex;
flex-wrap: wrap;
width: 100%;
background-color:#070707 ;
align-items: center;
justify-content: center;
direction: rtl;
padding: 30px 0px;
}

.contact_map_container{

}

.contact_details_container{
    direction: rtl;
    display: flex;
    flex-direction: column;
    padding: 50px;
    justify-content: center;
    text-align: start;
    color: white;
    
}

.location_name_container{
color: white;
font-size: 18px;
}

.locations_names_container{
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    gap: 10px;
}

.contact_us_title{
    font-size: 35px;
}