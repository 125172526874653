.jobs_container{
    display: flex;
    width: 100%;
    gap:10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding:5px 100px;
    
}

.job_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px black solid;
    min-height: 750px;
    width:600px;
    background-color:#3C3C3C;
}

.job_icon{
    width: 150px;
    height: 150px;
}

.job_header{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 450px;
}

.icon_container{
    width: 20%;
    display: flex;
    justify-content: center;
    padding: 20px 30px 0px 10px;
    flex: 2;
}

.text_container{
    width: 100%;
    flex: 4;
    color: white;
    text-align: end;
    padding: 10px 0px 0px 40px;
}

.job_header_buttom{
    display: flex;
    flex-direction: row-reverse;
    gap: 40px;
}

.job_title_container{
    width: 100%;
    background-color:#FFC938 ;
}
h2{
    direction: rtl;
    color: white;
}

.job_info_container{
    padding: 0px 30px;
}