.sticky-header {
  position: sticky;
  top: 0;
  padding: 10px 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.452);
  z-index: 1000;
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #FFC938 35%, #242424e3 45%);
}

.sticky-header nav {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  direction: rtl;
  width: 100%;
}

.sticky-header nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sticky-header nav ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap');

.sticky-header nav ul li {
  text-decoration: none;
  color: #fdc447;
  font-weight: bolder;
  -webkit-text-stroke: 0.5px #000000;
  padding: 2px 19px;
  height: 100%;
  font-size: 28px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  border-radius: 10px;
  cursor: pointer;
}

.sticky-header nav ul li:hover {
  background-color: #00000070;
  border: 2px black inset;
  padding: 0px 17px;
}

.logo-container {
  position: absolute;
  top: 0;
  left: 80px;
  z-index: 0;
}

.logo-container img {
  width: 170px;
  height: auto;
}

.contact_number_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 3px 10px;
}

.contact_number_container:hover {
  background-color: #ffd900;
  border: 1px black inset;
  padding: 2px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.contact_number {
  color: #3d3d3df6;
  font-weight: bolder;
  font-size: 28px;
}

.contact_number:hover {
  color: white;
}

.phone_icon {
  border: 2px solid #3D3D3D;
  padding: 3px;
  border-radius: 50%;
}

.navigation_list {
  display: flex;
  border-radius: 5px;
}
.navigation_list_mobile{
  display: flex;
  flex-direction: row;
}

.navigation_list_mobile div{
  text-decoration: none;
  color: #fdc447;
  font-weight: bolder;
  -webkit-text-stroke: 0.5px #000000;
  padding: 7px 19px;
  height: 100%;
  font-size: 16px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  border-radius: 10px;
}

.navigation_list_mobile div:active{
  background-color: #00000070;
  border: 2px black inset;
  padding: 5px 17px;
}

.mobile_header{
  position: sticky;
  top: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.452);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0px;
  background-color: #252525d7;
  gap: 10px;
}
