.wa_contact_container {
  display: flex;
  flex-direction: row;
  border: solid 1px black;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  padding: 10px 0;
  animation: waButton 2s infinite;
  cursor: pointer;
}

@keyframes waButton{
    0%{ background-color:#26d826e3;}
    50%{background-color: #10ee2e;}
    100%{background-color: #26d826e3;}
}

.button_text {
  direction: rtl;
  color: white;
  font-weight: bold;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 300px; /* Adjust the width as needed */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #3c3c3c;
  color: white;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 40px;
  color: white;
  cursor: pointer;
}

.form_container {
  display: flex;
  flex-direction: column;
  direction: rtl;
  text-align: start;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.input_area {
  width: 90%;
  padding: 5px;
}

.input_area_select {
  width: 95%;
  padding: 5px;
}

.submit_button_contact{
    width: 95%;
    padding: 10px;
    animation: waButton 2s infinite;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    direction: ltr;
}

.contact_title{
    margin: 0;
    padding: 0;
    text-align: center;;
}

.contact_img_icon{
    width: 60%;
}
