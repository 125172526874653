.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: 'Amatic';
  src: url('./assets/fonts/AmaticSC-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}




