.sticky-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #1d1d1d;
    color: white;
    text-align: center;
    padding: 15px 0;
    transition: transform 0.3s ease;
  }
  
  .sticky-footer.hidden {
    transform: translateY(100%);
  }
  
  .sticky-footer.visible {
    transform: translateY(0);
  }

  .sticky_container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 30px ;
    align-items: center;
  }

  @keyframes waBackgroung {
    0%  {background: linear-gradient(#11EB2D,#ecff3f);}
    50% {background: linear-gradient(#95ff3f,#16ff01);}
    100% {background: linear-gradient(#11EB2D,#a5ff3f);}
  }

  .wa_icon{
    border-radius: 50%;
    animation: waBackgroung 1s infinite;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .call_section{
    display: flex;
    justify-content: center;
    align-items: center;
    direction: rtl;
    background-color: #FFC938;
    padding: 5px 10px;
    font-size: 20px;
    font-weight: 900;
    -webkit-text-stroke: black  1px;
    border-radius: 5px;
    gap: 15px;
    cursor: pointer;
  }