.custom-slider {
  position: relative;
  width: 100%;
  max-width: 600px; /* Adjust based on your requirements */
  margin: auto;
  overflow: hidden;
}

.custom-image {
  width: 100%;
  display: block;
  object-fit: cover;
  height: 300px;
}

.custom-slide {
  display: none;
  transition: transform 0.1s ease-in;
}

.custom-active {
  display: block;
}

.custom-left-arrow, .custom-right-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  color: white;
  cursor: pointer;
  user-select: none;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.6);
}

.custom-left-arrow {
  left: 10px;
}

.custom-right-arrow {
  right: 10px;
}

.custom-dots {
  text-align: center;
  padding: 10px 0;
}

.custom-dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.custom-active-dot {
  background-color: #717171;
}
