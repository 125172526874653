.slider {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  
   .fade-out {
    opacity: 0;
    transition: opacity 5500ms ease-in-out;
  }
  
  .fade-in img {
    opacity: 1;
  }
  
  .fade-out img {
    opacity: 0;
  }
  
  .dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap:10px;
    padding: 40px;
    border-bottom: solid 1px black;
    background-color: rgba(0, 0, 0, 0.863);
  }

  @keyframes colorChange {
    0% { background:radial-gradient(circle,#ffd000 ,#ffae00); opacity: 0.8.5;}
    15% { background:radial-gradient(circle,#ffd51b,#ffd000);opacity: 0.9;}
    25% { background:radial-gradient(circle,#ffd376,#ffd000);opacity: 0.9.5;}
    40% { background:radial-gradient(circle,#ffd900,#ffd000);opacity: 1;}
    50% { background:radial-gradient(circle,#ffd900,#ffd000);opacity: 1;}
    60% { background:radial-gradient(circle,#ffd900,#ffd000);opacity: 1;}
    75% { background:radial-gradient(circle,#ffd376,#ffd000);opacity: 0.9.5;}
    85% { background:radial-gradient(circle,#ffd51b,#ffd000);opacity: 0.9;}
    100% { background:radial-gradient(circle,#ffd000,#ffae00);opacity: 0.8.5;}
  }
  
  .dot {
    cursor: pointer;
    padding: 5px 40px;
    border-radius: 5px;
    font-size: 30px;
    color: white;
    font-weight: bolder;
    display: flex;
    align-items: center;
    justify-content: center;
    animation:colorChange 1.5s infinite;
  }
  
  .dot.active {
    animation: none;
    background-color: black;
    border: 1px orange solid;
  }
  
  


  .info_container{ 
    background-color: rgba(0, 0, 0, 0.863);
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    direction: rtl;
    justify-content: center;
    align-items: center;
    gap: 100px;
    
  }

  .info_title{
    font-size: 35px;
    font-weight: bolder;
    -webkit-text-stroke: 1px black; 
    color: white;
  }

  .info_description{
    color: white;
    -webkit-text-stroke: 1px black;
    font-size: 22px;
    font-weight: bolder;
    padding: 0px 20px;
  }

  .job_button_scroll{
    animation: colorChange 1.5s infinite;
    padding: 8px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    
  }

  .job_button_scroll:hover:active{
    background-color:#ffb516 ;
    animation: none;
  }

  .jobs_buttons{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  


  