.description_container{
    display: flex;
    background-color: #353535;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

p{
    direction: rtl;
    color: white;
    font-size: 20px;
    font-weight: 600;
    text-align: start;
}

.p_container{
    padding: 10px 50px;
}

.description_title{
    font-size: 40px;
    color: white;
    text-align: start;
    direction: rtl;
}

.image_main_top{
    width: 80%;
}